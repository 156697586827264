import { ColumnDefinitionInputV1, ColumnTypeEnum } from '@/sdk/model/ColumnDefinitionInputV1';
import { ColumnDefinitionOutputV1 } from '@/sdk/model/ColumnDefinitionOutputV1';
import { ColumnRuleInputV1 } from '@/sdk/model/ColumnRuleInputV1';
import { TableDefinitionInputV1 } from '@/sdk/model/TableDefinitionInputV1';
import { TableDefinitionOutputV1 } from '@/sdk/model/TableDefinitionOutputV1';
import { RecomputeColumnTypeEnum1, RecomputeColumnTypeEnum2 } from '@/sdk/api/TableDefinitionsApi';
import {
  ColumnRuleFormulaCreatorInputV1,
  DatasourceOutputV1,
  GraphQLInputV1,
  GraphQLOutputV1,
  ItemSearchPreviewV1,
  sqDatasourcesApi,
  sqGraphQLApi,
  sqTableDefinitionsApi,
} from '@/sdk';
import { addTableDefinition } from '@/workbook/workbook.actions';
import {
  AgGridScalingColumnDefinition,
  BooleanTableCell,
  FormulaCompileResult,
  ItemTableCell,
  MaterializedTableHeader,
  MaterializedTableOutput,
  MaterializedTablePropertyColumnInput,
  NumericTableCell,
  ProcessedMaterializedTable,
  ProcessedTableCell,
  PROPERTY_COLUMN_MATCH_SEGMENT,
  ScalingTableColumnDefinition,
  TableDefinitionAccessSettings,
  TextTableCell,
  UOM_COLUMN_MATCH_SUFFIX,
} from '@/tableDefinitionEditor/tableDefinition.types';
import { runFormula as compileFormulaOrThrowError } from '@/formula/formula.utilities';
import { t } from 'i18next';
import {
  ColumnRule,
  ColumnTypeOptions,
  CombinedColumnRuleInputParameters,
} from '@/tableDefinitionEditor/columnRules/columnRule.constants';
import {
  columnRuleOutputToColumnRuleInput,
  getRuleTypeFromRuleInput,
} from '@/tableDefinitionEditor/columnRules/columnRule.utilities';
import { errorToast } from '@/utilities/toast.utilities';
import { setDisplayTableDefinitionEditor } from '@/worksheet/worksheet.actions';
import { resetTableDefinition, setTableDefinition } from '@/tableDefinitionEditor/tableDefinition.actions';
import _ from 'lodash';
import { NULL_PLACEHOLDER } from '@/tableBuilder/tableBuilder.constants';
import { toNumber } from '@/utilities/numberHelper.utilities';
import {
  ColumnRulesWithMetaData,
  RULES_WITH_PERMISSIONS,
} from '@/tableDefinitionEditor/columnRules/columnRuleBuilder.constants';
import { ColumnRuleWithMetadata } from '@/tableDefinitionEditor/columnRules/columnRuleBuilder.types';
import { fullyArchiveDatasource } from '@/utilities/datasources.utilities';
import { sqTableDefinitionStore } from '@/core/core.stores';
import { FormulaErrorInterface } from '@/formula/formula.types';
import { MAX_TABLE_ROWS } from '@/main/app.constants';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { HIDDEN_COLUMNS } from '@/tableDefinitionEditor/tableDefinition.constants';

export const itemIdColumnHasItemSearchRule = (columns: ScalingTableColumnDefinition[]): boolean =>
  columns
    .find((column) => column.columnName === SeeqNames.MaterializedTables.ItemIdColumn)
    ?.rules.some((rule) => rule.rule === SeeqNames.MaterializedTables.Rules.ItemSearch) ?? false;

export const getColumnsHiddenFromUser = (columns: ScalingTableColumnDefinition[]): string[] => {
  if (sqTableDefinitionStore.subscriberId) {
    return [SeeqNames.MaterializedTables.DatumIdColumn];
  }

  if (itemIdColumnHasItemSearchRule(columns)) {
    return [];
  }

  return [SeeqNames.MaterializedTables.ItemIdColumn];
};

export const getRuleTypeAndParameters = (
  columnRuleInput: ColumnRuleInputV1,
): {
  ruleType: ColumnRule;
  parameters: Partial<CombinedColumnRuleInputParameters>;
} => {
  const ruleType = getRuleTypeFromRuleInput(columnRuleInput);
  const parameters = columnRuleInput[ruleType]!;
  return { ruleType, parameters };
};

export const getColumnTypeFromText = (text: string): ColumnTypeEnum | undefined => {
  const columnType = ColumnTypeOptions.find((option) => t(option.label) === text);
  return columnType?.value;
};

export const getColumnRuleWithMetaDataFromLabel = (label: string): ColumnRuleWithMetadata => {
  const columnRule = ColumnRulesWithMetaData.find((rule) => rule.label === label);
  if (!columnRule) {
    throw new Error(`Column rule ${label} not found`);
  }
  return columnRule;
};

export const getColumnRuleWithMetaDataFromRule = (rule: string): ColumnRuleWithMetadata => {
  // In the case of Constant rules, the rule comes back like "stringConstant" whereas the ruleWithLabel is just
  // "constant", causing the exception to be thrown. Instead, we match on whether the rule contains "constant" as a
  // substring
  const columnRule = ColumnRulesWithMetaData.find(
    (ruleWithLabel) =>
      ruleWithLabel.rule === rule ||
      (ruleWithLabel.rule === 'constant' && rule.toLowerCase().includes(ruleWithLabel.rule.toLowerCase())),
  );
  if (!columnRule) {
    throw new Error(`Column rule ${rule} not found`);
  }
  return columnRule;
};

export const buildColumnDefsForAgGrid = (
  currentColumns: ScalingTableColumnDefinition[],
): AgGridScalingColumnDefinition[] => {
  return currentColumns.map((tableDefinitionColumnDef) => {
    const columnType = tableDefinitionColumnDef.columnType;
    const agGridCellDataType = {
      [ColumnTypeEnum.BOOLEAN]: 'text',
      [ColumnTypeEnum.NUMERIC]: 'number',
      [ColumnTypeEnum.TEXT]: 'text',
      [ColumnTypeEnum.UUID]: 'text',
      [ColumnTypeEnum.TIMESTAMPTZ]: 'text', // We may want to change this to date, it will be easier to see after
      // we fix the timestamp constant rule https://seeq.atlassian.net/browse/CRAB-42009
    }[columnType];
    return {
      flex: 1,
      field: tableDefinitionColumnDef.id,
      headerName: tableDefinitionColumnDef.columnName,
      headerValueGetter: () => {
        return tableDefinitionColumnDef.displayName;
      },
      cellDataType: agGridCellDataType,
      valueGetter: (params) => {
        const cell = params.data[tableDefinitionColumnDef.id] as ProcessedTableCell;
        const agColumnDef = params.column.getColDef() as AgGridScalingColumnDefinition;
        const value = getValueForCell(cell, agColumnDef, currentColumns);

        return value === '὇B' ? NULL_PLACEHOLDER : value;
      },
      valueFormatter: (params) => {
        const cell = params.data[tableDefinitionColumnDef.id] as ProcessedTableCell;
        return formatValueForCell(cell);
      },
      hide: getColumnsHiddenFromUser(currentColumns).includes(tableDefinitionColumnDef.columnName),
      suppressColumnsToolPanel: getColumnsHiddenFromUser(currentColumns).includes(tableDefinitionColumnDef.columnName),
    };
  }) as AgGridScalingColumnDefinition[];
};

export const buildRowDataForAgGrid = (
  agGridColumnDefinitions: AgGridScalingColumnDefinition[],
  materializedTable: ProcessedMaterializedTable | undefined,
) => {
  if (!materializedTable) {
    return null;
  }

  const materializedTableHeaders = materializedTable.headers;
  const rows = materializedTable.rows;
  return rows.map((row) => {
    const rowData: Record<string, any> = {};
    materializedTableHeaders.forEach((header: any, index: number) => {
      const agColumn = agGridColumnDefinitions.find((columnDef) => columnDef.headerName === header.name);
      const field = agColumn?.field;
      if (field) {
        rowData[field] = row[index];
      }
    });
    return rowData;
  });
};

export const tableDefinitionOutputToTableDefinitionInput = (
  tableDefinition: TableDefinitionOutputV1,
): TableDefinitionInputV1 => {
  return {
    name: tableDefinition.name,
    description: tableDefinition.description,
    dataId: tableDefinition.dataId,
    datasourceClass: tableDefinition.datasourceClass,
    datasourceId: tableDefinition.datasourceId,
    scopedTo: tableDefinition.scopedTo,
    subscriptionId: tableDefinition.subscription?.id,
    columnDefinitions: tableDefinition.columnDefinitions.map((columnDef) =>
      columnDefinitionOutputToColumnDefinitionInput(columnDef, tableDefinition.columnDefinitions),
    ),
  };
};

export const columnDefinitionOutputToColumnDefinitionInput = (
  columnDefinition: ColumnDefinitionOutputV1,
  otherColumns: ColumnDefinitionOutputV1[],
  accessSettings: TableDefinitionAccessSettings = {},
): ColumnDefinitionInputV1 => {
  return {
    columnType: columnDefinition.columnType,
    columnUom: columnDefinition.columnUom,
    columnName: columnDefinition.columnName,
    columnRules: columnDefinition.rules.map((rule) =>
      columnRuleOutputToColumnRuleInput(rule, otherColumns, accessSettings),
    ),
    isIndexed: columnDefinition.isIndexed,
  };
};

export const removeColumnDefinition = async (tableDefinitionId: string, columnDefinitionId: string) => {
  const { data: tableDefinitionOutput } = await sqTableDefinitionsApi.deleteColumnFromTableDefinition({
    id: tableDefinitionId,
    columnId: columnDefinitionId,
  });
  return tableDefinitionOutput;
};

export const addOrUpdateColumnDefinition = async (
  tableDefinitionId: string,
  columnDefinition: ColumnDefinitionInputV1,
  columnDefinitionId?: string,
  hasUniqueDatumIdColumn?: boolean,
): Promise<TableDefinitionOutputV1 | undefined> => {
  const addOrUpdatePromise = columnDefinitionId
    ? sqTableDefinitionsApi.modifyColumnInTableDefinition(columnDefinition, {
        columnId: columnDefinitionId,
        id: tableDefinitionId,
        recomputeColumnType: RecomputeColumnTypeEnum1.PROVIDEDWITHDEPENDENTS,
        hasUniqueDatumIdColumn,
      })
    : sqTableDefinitionsApi.addColumnsToTableDefinition(
        { columnDefinitions: [columnDefinition] },
        {
          id: tableDefinitionId,
          recomputeColumnType: RecomputeColumnTypeEnum2.PROVIDEDWITHDEPENDENTS,
          hasUniqueDatumIdColumn,
        },
      );

  try {
    const { data: tableDefinitionOutput } = await addOrUpdatePromise;

    return tableDefinitionOutput;
  } catch (e) {
    errorToast({ httpResponseOrError: e });
  }
};

/**
 * When updating, this function will update properties of the table definition except for columns. For that, use
 * {@link addOrUpdateColumnDefinition}
 */
export const createOrUpdateTableDefinition = async (
  tableDefinition: TableDefinitionInputV1,
  tableDefinitionId?: string,
): Promise<TableDefinitionOutputV1 | undefined> => {
  const createOrUpdatePromise = tableDefinitionId
    ? sqTableDefinitionsApi.updateTableDefinition(tableDefinition, { id: tableDefinitionId })
    : sqTableDefinitionsApi.createTableDefinition(tableDefinition);
  try {
    const { data: tableDefinitionOutput } = await createOrUpdatePromise;
    addTableDefinition(tableDefinitionOutput);
    return tableDefinitionOutput;
  } catch (e) {
    console.log('error creating table definition', e); // todo: CRAB-40758 throw error toast or do creation
  }
};

export const deleteTableRows = async (tableDefinitionId: string, rowIds: object[]) => {
  const REMOVE_MATERIALIZED_TABLE_ROWS =
    'mutation DeleteRows($tableId: String!, $rowIds: [RowIdInput!]!) {' +
    ' deleteRows(tableId: $tableId, rowIds: $rowIds) }';
  const inputObject: GraphQLInputV1 = {
    query: REMOVE_MATERIALIZED_TABLE_ROWS,
    variables: {
      tableId: tableDefinitionId,
      rowIds,
    },
  };
  try {
    const response = await sqGraphQLApi.graphql(inputObject);
    if (response.data.errors) {
      errorToast({ httpResponseOrError: response });
    }
    return response.data;
  } catch (e) {
    errorToast({ httpResponseOrError: e });
    return {};
  }
};

export const getMaterializedTable = async (
  tableDefinitionId: string,
  columnsToInclude?: string[],
  propertiesToInclude?: MaterializedTablePropertyColumnInput[],
): Promise<GraphQLOutputV1> => {
  const GET_MATERIALIZED_TABLE_QUERY =
    'query GetTable($id: String!, $filter: FilterInput, $limit: Int!, $columnsToInclude: [String!], $propertiesToInclude: [PropertiesForItemUUIDColumnInput!]) {' +
    ' table(id: $id, filter: $filter, limit: $limit, columnsToInclude: $columnsToInclude, propertiesToInclude: $propertiesToInclude) { rows headers { name' +
    ' type }' +
    ' hasMore } }';
  const inputObject: GraphQLInputV1 = {
    query: GET_MATERIALIZED_TABLE_QUERY,
    variables: {
      id: tableDefinitionId,
      limit: MAX_TABLE_ROWS,
      columnsToInclude,
      propertiesToInclude,
    },
  };
  try {
    const response = await sqGraphQLApi.graphql(inputObject);
    if (response.data.errors) {
      errorToast({ httpResponseOrError: response });
    }
    return response.data;
  } catch (e) {
    errorToast({ httpResponseOrError: e });
    return {};
  }
};

/**
 * Validates a formula for an instance of a formulaCreatorRule. In order to compile the formula, this function will
 * find the first viable item in each column that the formula references and use those as parameters. If no viable
 * items are found, the function will return an error. If viable parameters are found but there is a compilation
 * error, the compilation error will be returned.
 */
export const validateFormulaForCreator = async (
  formulaRule: ColumnRuleFormulaCreatorInputV1,
  materializedTable?: ProcessedMaterializedTable,
  columnDefinitions?: ColumnDefinitionOutputV1[],
): Promise<FormulaCompileResult> => {
  if (!materializedTable || !columnDefinitions || columnDefinitions.length === 0) {
    return {
      success: false,
      errors: [{ message: 'No items in the table to run the formula on', column: -1, line: -1 }],
    };
  }
  const errors: FormulaErrorInterface[] = [];
  const materializedTableHeaders = materializedTable.headers;
  const parameters = formulaRule.parameters || [];
  const columnIndexes = formulaRule.columnIndexes || [];

  const parametersForCompile = parameters.map((parameter, index) => {
    const indexOfColumnInTableDefinition = columnIndexes[index] - 1;
    const columnName = columnDefinitions[indexOfColumnInTableDefinition].columnName;
    const indexOfColumnInMaterializedTable = materializedTableHeaders.findIndex((header) => header.name === columnName);
    const rowWithValidItem = materializedTable.rows.find((row) =>
      isItemTableCell(row[indexOfColumnInMaterializedTable]),
    );
    const idOfFirstValidItemInGivenColumn = rowWithValidItem
      ? (rowWithValidItem[indexOfColumnInMaterializedTable] as ItemTableCell).uuid
      : undefined;

    if (idOfFirstValidItemInGivenColumn === undefined) {
      errors.push({
        message: `No valid items in column ${columnName} which is referred to by parameter: ${parameter}`,
        column: -1,
        line: -1,
      });
    }
    return `${parameter}=${idOfFirstValidItemInGivenColumn}`;
  });

  if (errors.length > 0) {
    return { success: false, errors };
  }

  try {
    await compileFormulaOrThrowError(formulaRule.formula, parametersForCompile);
    return { success: true, errors: [] };
  } catch (e: any) {
    return { success: false, errors: e };
  }
};

export async function editTableDefinition(tableDefinition: ItemSearchPreviewV1) {
  resetTableDefinition();
  try {
    const { data: tableDefinitionData } = await sqTableDefinitionsApi.getTableDefinition({ id: tableDefinition.id });
    setTableDefinition(tableDefinitionData);
    setDisplayTableDefinitionEditor(true);
  } catch (e) {
    errorToast({ httpResponseOrError: e });
  }
}

export const processMaterializedTable = (
  rawMaterializedTable: MaterializedTableOutput | undefined,
  columns: ScalingTableColumnDefinition[],
): ProcessedMaterializedTable | undefined => {
  if (!rawMaterializedTable) {
    return undefined;
  }
  const headers = rawMaterializedTable.headers;
  const rows = rawMaterializedTable.rows;

  const headerMap = headers.reduce((map, header) => {
    map.set(header.name, header);
    return map;
  }, new Map<string, MaterializedTableHeader>());

  const processedRows: ProcessedTableCell[][] = rows.map((row) => {
    const newRow: ProcessedTableCell[] = row.map((cell, columnIndex) => {
      const header = headers[columnIndex];
      const potentialValue = cell;
      const potentialNumericValue = toNumber(potentialValue);
      if (header.type === ColumnTypeEnum.NUMERIC && !_.isNil(potentialNumericValue)) {
        const potentialUomColumn = headerMap.get(`${header.name}${UOM_COLUMN_MATCH_SUFFIX}`);
        const potentialUomColumnIndex = headers.findIndex((header) => header.name === potentialUomColumn?.name);
        const potentialUomColumnValue = potentialUomColumnIndex > -1 ? row[potentialUomColumnIndex] : undefined;
        // If there was not a uom column, then check the column definition for a uom override
        const uomColumnValue =
          potentialUomColumnValue ?? columns.find((col) => col.columnName === header.name)?.columnUom;
        return {
          value: potentialNumericValue,
          uom: uomColumnValue as string | undefined,
        };
      }
      if (header.type === ColumnTypeEnum.UUID && !_.isNil(potentialValue)) {
        const potentialItemColumn = columns.find((col) => col.columnName === header.name);
        const potentialPropertyColumns = potentialItemColumn?.additionalProperties
          ? [...potentialItemColumn.additionalProperties]
          : [];

        const potentialPropertyToDisplay = potentialItemColumn?.propertyToDisplay;
        if (potentialPropertyToDisplay) {
          potentialPropertyColumns.push(potentialPropertyToDisplay);
        }
        let fetchedProperties: Record<string, any> = {};
        potentialPropertyColumns.forEach((property) => {
          const propertyToDisplayHeaderIndex = headers.findIndex(
            (propertyHeader) => propertyHeader.name === `${header.name}${PROPERTY_COLUMN_MATCH_SEGMENT}${property}`,
          );
          fetchedProperties[property] =
            propertyToDisplayHeaderIndex > -1 ? (row[propertyToDisplayHeaderIndex] as string) : undefined;
        });

        return {
          uuid: potentialValue as string,
          fetchedProperties,
        } as ItemTableCell;
      }
      if (header.type === ColumnTypeEnum.BOOLEAN && !_.isNil(potentialValue)) {
        return {
          value: !!potentialValue,
        } as BooleanTableCell;
      }

      // make it a default text cell
      return {
        value: potentialValue ? potentialValue.toString() : undefined,
      };
    });
    return newRow;
  });

  // Filter out property columns and uom columns from headers and rows so we do not put extra data in the store
  const propertyAndUomColumnIndices = headers
    .map((header, index) => ({ name: header.name, index }))
    .filter(({ name }) => name.includes(PROPERTY_COLUMN_MATCH_SEGMENT) || name.includes(UOM_COLUMN_MATCH_SUFFIX))
    .map(({ index }) => index);
  const filteredHeaders = headers.filter((_, index) => !propertyAndUomColumnIndices.includes(index));
  const filteredRows = processedRows.map((row) =>
    row.filter((_, index) => !propertyAndUomColumnIndices.includes(index)),
  );

  return {
    headers: filteredHeaders,
    rows: filteredRows,
    hasMore: rawMaterializedTable.hasMore,
  };
};

export const isItemTableCell = (cell: any): cell is ItemTableCell => {
  return cell && cell.uuid && typeof cell.uuid === 'string';
};

export const isTextTableCell = (cell: any): cell is TextTableCell => {
  return cell && !isItemTableCell(cell) && !isNumericTableCell(cell) && !isBooleanTableCell(cell);
};

export const isNumericTableCell = (cell: any): cell is NumericTableCell => {
  return cell && !_.isNil(cell.value) && typeof cell.value === 'number';
};

export const isBooleanTableCell = (cell: any): cell is BooleanTableCell => {
  return cell && !_.isNil(cell.value) && typeof cell.value === 'boolean';
};

export const getValueForCell = (
  cell: ProcessedTableCell,
  agColumnDef: AgGridScalingColumnDefinition,
  currentColumns: ScalingTableColumnDefinition[],
): string | number | boolean | undefined => {
  if (isNumericTableCell(cell)) {
    return cell.value;
  } else if (isItemTableCell(cell)) {
    const propertyForDisplay = currentColumns.find(
      (column) => column.displayName === agColumnDef.headerValueGetter() && column.propertyToDisplay,
    )?.propertyToDisplay;
    return propertyForDisplay && cell.fetchedProperties ? cell.fetchedProperties[propertyForDisplay] : cell.uuid;
  } else if (isTextTableCell(cell) || isBooleanTableCell(cell)) {
    return cell.value;
  } else {
    return cell;
  }
};

export const formatValueForCell = (cell: ProcessedTableCell): string | undefined => {
  if (isNumericTableCell(cell)) {
    return `${cell.value}${cell.uom ?? ''}`;
  }
  if (isBooleanTableCell(cell)) {
    return cell.value ? 'true' : 'false';
  }
  if (isTextTableCell(cell)) {
    return cell.value ?? NULL_PLACEHOLDER;
  }
};

/**
 * Retrieves the data source for a table definition.
 */
export const getTableDefinitionDataSource = async (
  tableDefinitionId: string,
): Promise<DatasourceOutputV1 | undefined> => {
  const {
    data: { datasources },
  } = await sqDatasourcesApi.getDatasources({
    datasourceId: tableDefinitionId.toLocaleLowerCase(),
  });

  return datasources?.at(0);
};

/**
 * Deletes datasource associated with a table definition which
 * will delete all created items associated with the table definition.
 */
export async function deleteTableItems(tableDefinitionId: string): Promise<void> {
  const tableDefinitionDatasource = await getTableDefinitionDataSource(tableDefinitionId);
  if (tableDefinitionDatasource) {
    await fullyArchiveDatasource(tableDefinitionDatasource.id);
  }
}

export const getColumnsContainingRulesWithPermissions = (
  columns: ColumnDefinitionOutputV1[],
): ColumnDefinitionOutputV1[] =>
  columns.filter((column) => column.rules.some((rule) => RULES_WITH_PERMISSIONS.includes(rule.rule)));

/**
 * Updates the column definitions of a table.
 *
 * @param columnsToUpdate - The array of column definitions to update.
 * @param tableDefinitionId - The ID of the table definition.
 * @param allColumns - The array of all column definitions for the table.
 *
 * @returns A promise that resolves to the updated table definition, or undefined if there are no columns to update.
 */
export const updateColumnDefinitions = async (
  columnsToUpdate: ColumnDefinitionOutputV1[],
  tableDefinitionId: string,
  allColumns: ScalingTableColumnDefinition[],
  accessSettings?: TableDefinitionAccessSettings,
): Promise<TableDefinitionOutputV1 | undefined> => {
  let tableDefinitionOutput: TableDefinitionOutputV1 | undefined;

  // Do not update in parallel with Promise.all as columns may depend on each other
  for (const column of columnsToUpdate) {
    tableDefinitionOutput = await addOrUpdateColumnDefinition(
      tableDefinitionId,
      columnDefinitionOutputToColumnDefinitionInput(column, allColumns, accessSettings),
      column.id,
    );
  }

  return tableDefinitionOutput;
};

/**
 * Updates the columns containing rules with permissions with the new
 * access settings
 */
export const updateColumnsContainingRulesWithPermissions = async (accessSettings: TableDefinitionAccessSettings) =>
  await updateColumnDefinitions(
    getColumnsContainingRulesWithPermissions(sqTableDefinitionStore.columns),
    sqTableDefinitionStore.id,
    sqTableDefinitionStore.columns,
    accessSettings,
  );

export const getColumnOptions = (
  allColumnDefinitions: ScalingTableColumnDefinition[],
  hiddenColumns = HIDDEN_COLUMNS,
) =>
  allColumnDefinitions
    .filter((column) => !hiddenColumns.includes(column.columnName))
    .map((column) => ({
      text: sqTableDefinitionStore.getColumnDisplayName(column.columnName),
      value: column.columnName,
    }));
