/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.0.0-v202408162332-CD
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqUserGroupsApiClass {
 
   constructor() {}

  /**
   * @summary Add a user to a user group
   * @param {string} userGroupId - ID of the user group to add user or user group to
   * @param {string} identityId - ID of the user or user group to add.
   */
  public addIdentityToUserGroup(
    {
      userGroupId,
      identityId
    } : {
      userGroupId: string,
      identityId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(userGroupId)) {
      throw new Error("'userGroupId' parameter required");
    }

    if (isNil(identityId)) {
      throw new Error("'identityId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/usergroups/${encodeURIComponent(String(userGroupId))}/${encodeURIComponent(String(identityId))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.UserGroupOutputV1>;
  }

  /**
   * @summary Archive a user group
   * @param {string} userGroupId - ID of user group to archive
   * @param {boolean} [removePermissions=false] - Whether to remove permissions associated with the group being archived
   */
  public archiveUserGroup(
    {
      userGroupId,
      removePermissions
    } : {
      userGroupId: string,
      removePermissions?: boolean
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(userGroupId)) {
      throw new Error("'userGroupId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/usergroups/${encodeURIComponent(String(userGroupId))}/archive`,

      params: omitBy({
        ['removePermissions']: removePermissions
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.UserGroupOutputV1>;
  }

  /**
   * @summary Create a user group hosted within Seeq
   */
  public createUserGroup(
    body: models.UserGroupInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/usergroups`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.UserGroupOutputV1>;
  }

  /**
   * @summary Disable a user group
   * @param {string} userGroupId - ID of user group to disable
   * @param {boolean} [removePermissions=false] - Whether to remove permissions associated with the group being disabled
   */
  public disableUserGroup(
    {
      userGroupId,
      removePermissions
    } : {
      userGroupId: string,
      removePermissions?: boolean
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(userGroupId)) {
      throw new Error("'userGroupId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/usergroups/${encodeURIComponent(String(userGroupId))}/disable`,

      params: omitBy({
        ['removePermissions']: removePermissions
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.UserGroupOutputV1>;
  }

  /**
   * @summary Get the collection of usergroups used to manage capabilities
   * @param {string} [nameSearch] - Search text by which to filter capability groups&#x27; names.
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination level, the total number of collection items that will be returned in this page of results
   */
  public getCapabilityUserGroups(
    {
      nameSearch,
      offset,
      limit
    } : {
      nameSearch?: string,
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/usergroups/capabilities`,

      params: omitBy({
        ['nameSearch']: nameSearch,
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.IdentityPreviewListV1>;
  }

  /**
   * @summary Get a user group
   * @param {string} userGroupId - ID of the user group to get
   * @param {boolean} [includeMembers=true] - Whether to include members in the output
   */
  public getUserGroup(
    {
      userGroupId,
      includeMembers
    } : {
      userGroupId: string,
      includeMembers?: boolean
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(userGroupId)) {
      throw new Error("'userGroupId' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/usergroups/${encodeURIComponent(String(userGroupId))}`,

      params: omitBy({
        ['includeMembers']: includeMembers
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.UserGroupOutputV1>;
  }

  /**
   * @summary Get a collection of usergroups
   * @param {string} [nameSearch] - Search text by which to filter user groups&#x27; names.
   * @param {string} [datasourceIdSearch] - Search text by which to filter user groups&#x27; directories.
   * @param {boolean} [isEnabled] - Whether to filter user groups to only user groups who are enabled.
   * @param {string} [sortOrder=name asc] - A field by which to order the user groups followed by a space and &#x27;asc&#x27; or &#x27;desc&#x27;. Field name can be one of: name, directory
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination level, the total number of collection items that will be returned in this page of results
   */
  public getUserGroups(
    {
      nameSearch,
      datasourceIdSearch,
      isEnabled,
      sortOrder,
      offset,
      limit
    } : {
      nameSearch?: string,
      datasourceIdSearch?: string,
      isEnabled?: boolean,
      sortOrder?: string,
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/usergroups`,

      params: omitBy({
        ['nameSearch']: nameSearch,
        ['datasourceIdSearch']: datasourceIdSearch,
        ['isEnabled']: isEnabled,
        ['sortOrder']: sortOrder,
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.IdentityPreviewListV1>;
  }

  /**
   * @summary Create or update multiple user groups
   */
  public putUserGroups(
    body: models.PutUserGroupsInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/usergroups/batch`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ItemBatchOutputV1>;
  }

  /**
   * @summary Remove a user from a user group
   * @param {string} userGroupId - ID of the user group to remove user from
   * @param {string} identityId - ID of the user or user group to remove.
   */
  public removeIdentityFromUserGroup(
    {
      userGroupId,
      identityId
    } : {
      userGroupId: string,
      identityId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(userGroupId)) {
      throw new Error("'userGroupId' parameter required");
    }

    if (isNil(identityId)) {
      throw new Error("'identityId' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/usergroups/${encodeURIComponent(String(userGroupId))}/${encodeURIComponent(String(identityId))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.UserGroupOutputV1>;
  }

  /**
   * @summary Remove a user group
   * @param {string} userGroupId - ID of the user group to remove
   */
  public removeUserGroup(
    {
      userGroupId
    } : {
      userGroupId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(userGroupId)) {
      throw new Error("'userGroupId' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/usergroups/${encodeURIComponent(String(userGroupId))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Update name or description of a user group
   * @param {string} userGroupId - ID of the user group to update
   */
  public updateUserGroup(
    body: models.UserGroupInputV1,
    {
      userGroupId
    } : {
      userGroupId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(userGroupId)) {
      throw new Error("'userGroupId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/usergroups/${encodeURIComponent(String(userGroupId))}`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.UserGroupOutputV1>;
  }

}


export const sqUserGroupsApi = new sqUserGroupsApiClass();
